import React from 'react';
import advocateProfilePic from '../../../assets/images/advocates-details/manju.jpg'; // Add the correct image path

const AdvocateKomalDetails = () => {
  return (
    <section className='team-details'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-lg-5'>
            <div className='team-details__content'>
              <h2 className='team-details__title'>Read my story</h2>
              <p className='team-details__text'>
                Mr. Komal did his LLB from K.V. Ranga Reddy Law College,
                Hyderabad. He enrolled as an advocate in the year 2023. He is
                currently practising at Civil and Criminal Courts at Hyderabad.
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={advocateProfilePic} alt='Mr. Komal Varma' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>B. Komal Varma LLB</h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvocateKomalDetails;
